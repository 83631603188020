import React from "react";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";

import { arrowAnimationDuration, arrowSecondaryDelay } from "@utils/variables";

const arrowAnimation = keyframes`
  0% { transform: scale(0) translateZ(0); }
  3.6% { transform: scale(0) translateZ(0); }
  8.4% { transform: scale(1) translateZ(0); }
  54% { transform: scale(1) translateZ(0); }
  61.2% { transform: scale(0) translateZ(0); }
  100% { transform: scale(0) translateZ(0); }
`;

const pathLength = (strokeDashoffset: number) => keyframes`
  0% { stroke-dashoffset: 1000; }
  2.4% { stroke-dashoffset: 1000; }
  9.6% { stroke-dashoffset: ${strokeDashoffset}; }
  56.4% { stroke-dashoffset: ${strokeDashoffset}; }
  61.2% { stroke-dashoffset: 1000; }
  100% { stroke-dashoffset: 1000; }
`;

const StyledImg = tw.svg`
  absolute! -bottom-20 left-55 w-60 transform -skew-x-10 skew-y-10 -rotate-90
  xl:bottom-0 xl:-left-100 xl:w-160 xl:rotate-0 xl:skew-x-0 xl:skew-y-0
`;

const StyledArrow = styled.polygon`
  transform: scale(0);
  transform-origin: 27.5px 143.5px;
  animation: ${arrowAnimation} ${arrowAnimationDuration}s ${arrowSecondaryDelay}s infinite;
`;

const StyledLine = styled.path<{ animationDelay: number, shortAnimation?: boolean }>`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${arrowAnimationDuration}s infinite;
  animation-name: ${({ shortAnimation }) => pathLength(shortAnimation ? 790 : 890) };
  animation-delay: ${({ animationDelay }) => animationDelay ? `${arrowSecondaryDelay - animationDelay}s` : `${arrowSecondaryDelay}s` };
`;

const ArrowSecondary = () => (
  <StyledImg viewBox="0 0 160 310">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <StyledArrow fill="#F06140" points="0 171 55 153.813536 29.6775923 116" />
      <g stroke="#761113" strokeWidth="0.5" strokeLinejoin="round">
        <StyledLine animationDelay={ 0 } d="M 115 68 l -84 68" />
        <StyledLine animationDelay={ .2 } d="M 86 112 l -47 36" />
      </g>
      <g transform="translate(17.000000, 181.000000)" stroke="#761113" strokeLinejoin="round" strokeWidth="0.5">
        <StyledLine animationDelay={ .5 } d="M 88 90 l 81 -96" />
        <StyledLine animationDelay={ .1 } d="M 0 130 l 165 -168" />
      </g>
      <g stroke="#761113" strokeWidth="0.5" strokeLinejoin="round">
        <StyledLine animationDelay={ .5 } shortAnimation d="M 39 47 l 110 -67" />
      </g>
    </g>
  </StyledImg>
);

export default ArrowSecondary;
