import React, { useState, useLayoutEffect } from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import { graphql, useStaticQuery } from "gatsby";
import { lang, rawName } from "@utils/helpers";

import PortableText from "@components/portableText";
import LinkPrimary from "@components/links/primary";
import ArrowPrimary from "@components/arrows/primary";
import ArrowSecondary from "@components/arrows/secondary";

type CTAProps = {
  scroll: any,
  width: number,
  triggerUseLayoutEffect?: number,
  trigger?: boolean
};

const Wrapper = tw.div`
  relative pt-48 px-20 pb-100 text-center
  sm:pt-65
  lg:pt-120 lg:pb-125
`;

const HeaderWrapper = tw(motion.div)`
  max-w-260 mx-auto mb-30 text-xl leading-40
  sm:max-w-none
  lg:mb-40 lg:text-4xl lg:leading-64
`;

const Header = styled.div`
  ${tw`inline -tracking-016 lg:-tracking-028`}

  span {
    ${tw`pb-5 text-orange border-orange border-b-2`}
  }
`;

const Paragraph = tw(motion.p)`
  mb-20 text-sm text-second leading-22 -tracking-021
  lg:mr-55 lg:mb-0 lg:text-md lg:leading-42
`;

const CTAWrapper = tw.div`lg:flex lg:justify-center lg:items-center`;

const CTA = ({ scroll, width, triggerUseLayoutEffect = 0, trigger = false }: CTAProps) => {
  const { t } = useTranslation();
  const CTARef = React.useRef();

  const [sectionStart, setSectionStart] = useState(0);
  const [sectionEnd, setSectionEnd] = useState(.5);

  const motionRange = [sectionStart + 50, sectionEnd];
  const motionRangeLong = [sectionStart, sectionEnd + 50];

  const y = useTransform(scroll, motionRange, [150, 0]);
  const rotate = useTransform(scroll, motionRange, [-9, 0]);
  const opacity = useTransform(scroll, motionRange, [0, 1]);
  const left = useTransform(scroll, motionRangeLong, [-350, 0]);
  const right = useTransform(scroll, motionRangeLong, [350, 0]);

  const { allSanityCta } = useStaticQuery(graphql`
    query {
      allSanityCta {
        nodes {
          first { en, pl }
          second { _rawEn, _rawPl }
          small_text { en, pl }
          btn_text { en, pl }
        }
      }
    }
  `);
  const data = allSanityCta.nodes[0];

  useLayoutEffect(() => {
    if (!CTARef.current) return;

    const offsetStart = CTARef.current.offsetTop - window.innerHeight;
    const offsetEnd = offsetStart + CTARef.current.clientHeight;

    setSectionStart(offsetStart);
    setSectionEnd(offsetEnd);
  }, [triggerUseLayoutEffect, trigger]);

  return (
    <Wrapper ref={CTARef}>
      <ArrowPrimary />
      <HeaderWrapper style={ width >= 1024 && { y, opacity, rotate } }>
        <Header>
          { data.first[lang] }
          <PortableText blocks={data.second[rawName]} />
        </Header>
      </HeaderWrapper>
      <CTAWrapper>
        <Paragraph style={ width >= 1024 && { x: left, opacity } }>
          { data.small_text[lang] }
        </Paragraph>
        <motion.div style={ width >= 1024 && { x: right, opacity } }>
          <LinkPrimary className={'mx-auto lg:mx-0'} text={data.btn_text[lang]} />
        </motion.div>
      </CTAWrapper>
      <ArrowSecondary />
    </Wrapper>
  );
};

export default CTA;
